/**
 * A custom hook to handle / control the scroll bar of HTML element
 * When the focus is out from TypeForm, set overflow inherit to html tag
 * 
 * @hook useHtmlScroll
 */

import { useEffect, useRef } from "react";


export function useHtmlScroll() {
    const htmlElementRef = useRef<HTMLElement | null>(null);

    const hideScrollBar = () => {
        if (htmlElementRef.current) {
            htmlElementRef.current.style.overflowY = `hidden`;
        }
    };

    const resetScrollBar = () => {
        if (htmlElementRef.current) {
            htmlElementRef.current.style.overflowY = `inherit`;
        }
    };

    useEffect(() => {
        if (!htmlElementRef.current && document) {
            htmlElementRef.current = document.documentElement;
        }

        return () => {
            resetScrollBar();
        }
    }, []);

    return {
        hideScrollBar,
        resetScrollBar
    }
}