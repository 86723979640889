import React, { useRef } from 'react';
import { List, ListItem, useDisclosure } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { StyledFlexBox } from '../Atoms';
import { HiveDrawer } from '../Drawer';
import { NavigationLink } from './NavigationLink';

import { NavigationConstants } from '@hive-microsite-ui/constants';
import { useHtmlScroll } from '@hive-microsite-ui/hooks/useHtmlScroll';

const MenuItemFramerVariants = {
  visible: {
    y: 0,
    opacity: 1,
    ease: 'easeInOut',
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  hidden: {
    y: 50,
    opacity: 0,
    ease: 'easeInOut',
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const MenuFramerVariants = {
  visible: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  hidden: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

interface DrawerNavigationMenuProps {
  isOpen: boolean;
  onCloseDrawer?: () => void;
}

export const HeaderNavigationMenu: React.FC = () => {
  const { resetScrollBar } = useHtmlScroll();

  return (
    <StyledFlexBox
      width={`full`}
      flexDirection={`row`}
      gap={{
        md: 6,
        lg: 8,
      }}
      onClick={() => alert(`on click navheader`)}
    >
      {...NavigationConstants.map((linkItem, index) => {
        return (
          <NavigationLink
            key={index}
            label={linkItem.label}
            title={linkItem.title}
            href={linkItem.href}
            goToSectionId={linkItem.goToSection}
            onClick={resetScrollBar}
          />
        );
      })}
    </StyledFlexBox>
  );
};

export const DrawerNavigationMenu: React.FC<DrawerNavigationMenuProps> = ({ isOpen, onCloseDrawer }) => {
  const containerRef = useRef(null);

  return (
    <StyledFlexBox
      as={motion.nav}
      style={{
        width: `100%`,
      }}
      initial="hidden"
      animate={isOpen ? 'visible' : 'hidden'}
      custom={614}
      ref={containerRef}
    >
      <List as={motion.ul} variants={MenuFramerVariants} gap={8} w={`full`} display={`flex`} flexDirection={`column`}>
        {...NavigationConstants.map((linkItem, index) => {
          return (
            <ListItem as={motion.li} variants={MenuItemFramerVariants} key={index}>
              <NavigationLink
                label={linkItem.label}
                title={linkItem.title}
                href={linkItem.href}
                goToSectionId={linkItem.goToSection}
                onCloseDrawer={onCloseDrawer}
              />
            </ListItem>
          );
        })}
      </List>
    </StyledFlexBox>
  );
};

export const MobileDrawerNavigationMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <HiveDrawer
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      drawerProps={{ placement: 'right', size: 'full', blockScrollOnMount: false }}
      renderDrawerContent={() => <DrawerNavigationMenu isOpen={isOpen} onCloseDrawer={onClose} />}
    />
  );
};
