import React, { ReactElement, useMemo } from 'react';
import ReactMultiCarousel, { ResponsiveType } from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';
import './style.css';

export type HiveCarouselProps = {
  items?: React.ReactElement[];
  responsive?: ResponsiveType;
  renderCarouselItems?: () => ReactElement[];
  className?: string;
};

export const Carousel: React.FC<HiveCarouselProps> = (props) => {
  const { responsive, className, renderCarouselItems } = props;
  const defaultResponsive = useMemo(
    () => ({
      desktop: {
        breakpoint: {
          max: 767,
          min: 465,
        },
        items: 1,
        partialVisibilityGutter: 0,
      },
      mobile: {
        breakpoint: {
          max: 464,
          min: 0,
        },
        items: 1,
        partialVisibilityGutter: 0,
      },
    }),
    [],
  );

  return (
    <ReactMultiCarousel
      additionalTransfrom={0}
      arrows={false}
      autoPlaySpeed={3000}
      centerMode={false}
      className={`carousel ${className ?? ''}`}
      containerClass="container"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite={false}
      itemClass="carousel-item"
      keyBoardControl
      minimumTouchDrag={80}
      partialVisible
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={true}
      responsive={responsive ?? defaultResponsive}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={true}
      sliderClass=""
      slidesToSlide={1}
      swipeable
    >
      {typeof renderCarouselItems === `function` && renderCarouselItems?.()}
    </ReactMultiCarousel>
  );
};
