const colors = {
  brand: {
    100: `#00F9FF`,
  },
  primary: {
    100: `#00F9FF`,
    80: `#00F9FFCC`,
  },
  secondary: {
    100: `#DEE5E8`,
  },
  bg: {
    primary: '#02232E',
    dark: '#031921',
    gradient: 'linear-gradient(180deg, #384347 -85.94%, #031921 100%)',
    form: `#192c33`,
  },
  custom: {
    primary: `#00F9FF`,
    secondary: `#02232E`,
    success: `#29BB66`,
    error: '#E84C3D',
    background: `linear-gradient(0deg, rgba(0, 40, 53, 1) 2%, rgba(0, 40, 53, 1) 40%, rgba(0, 40, 53, 1) 100%)`,
    shadow: `#02232E`,
    text: {
      primary: `#FFFFFF`,
      secondary: `#02232E`,
      gradient: `rgba(3, 25, 33, 1)`,
    },
  },
};

export { colors };
